import {
  all,
  takeEvery,
  put,
  fork,
  call,
  putResolve,
} from "redux-saga/effects";
import { AUTH_TOKEN, SIGNIN, SIGNOUT, REFRESH_TOKEN } from "../constants/Auth";
import {
  BI_ACCESS,
  COMPANY,
  COMPANY_NAME,
  COMPANY_TYPE,
  USERNAME,
  EMAIL,
  IS_STAFF,
  FIRST_PRODUCTION_DATE,
  STAGES,
  MEASUREMENT_UNITS,
  FULL_NAME,
  DEPARTMENT,
} from "redux/constants/User";
import {
  SIDE_NAV_STYLE_CHANGE,
  THEME_OPTION,
  NAV_TYPE_CHANGE,
  LANG_OPTION,
} from "../constants/Theme";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
} from "../actions/Auth";
import {
  onHeaderNavColorChange,
  onTopNavColorChange,
  onNavTypeChange,
  onNavStyleChange,
  onLocaleChange,
  onSwitchTheme,
  toggleCollapsedNav,
} from "redux/actions/Theme";
import { disconnect } from "../actions/Websocket";

import JwtAuthService from "services/JwtAuthService";
import UserService from "services/API_services/UserService";

const dispatch = putResolve;

export function* signInWithUsername() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    // const {data} = payload;
    try {
      const user = yield call(JwtAuthService.login, payload);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        // Set Authentication Tokens
        localStorage.setItem(AUTH_TOKEN, user.access);
        localStorage.setItem(REFRESH_TOKEN, user.refresh);

        // Fetch user , company & preferences data
        const userInfo = yield call(UserService.getUserInfo, payload);
        localStorage.setItem(
          FIRST_PRODUCTION_DATE,
          userInfo.company.first_production_date
        );
        localStorage.setItem(IS_STAFF, userInfo.is_staff);
        localStorage.setItem(COMPANY, window.btoa(userInfo.company.pk));
        localStorage.setItem(COMPANY_NAME, userInfo.company.name);
        localStorage.setItem(USERNAME, userInfo.username);
        localStorage.setItem(EMAIL, userInfo.email);
        localStorage.setItem(FULL_NAME, userInfo.full_name);
        localStorage.setItem(DEPARTMENT, userInfo.department);
        localStorage.setItem(BI_ACCESS, userInfo.company.bi_access);

        if (userInfo.company.type !== null) {
          localStorage.setItem(COMPANY_TYPE, userInfo.company.type);
        } else {
          localStorage.setItem(COMPANY_TYPE, "-");
        }

        localStorage.setItem(STAGES, JSON.stringify(userInfo.company.stages));

        yield put(authenticated(user.access));

        // Set preferred theme
        const changedTheme =
          userInfo.preferences.theme === "Light" ? "light" : "dark";
        yield put(onSwitchTheme(changedTheme));
        localStorage.setItem(
          THEME_OPTION,
          userInfo.preferences.theme === "Light" ? "light" : "dark"
        );

        localStorage.setItem(
          MEASUREMENT_UNITS,
          JSON.stringify(userInfo.preferences.measurement_units)
        );

        // Set prefer layout between side and top and side navigation color if layout is side
        yield put(onHeaderNavColorChange(""));
        if (userInfo.preferences.navigation_layout === "Top") {
          yield put(onTopNavColorChange("#2f3a50"));
          yield put(toggleCollapsedNav(false));
          yield put(onNavTypeChange("TOP"));
          localStorage.setItem(NAV_TYPE_CHANGE, "TOP");
        } else {
          yield put(onNavTypeChange("SIDE"));
          localStorage.setItem(NAV_TYPE_CHANGE, "SIDE");
          yield put(
            onNavStyleChange(
              userInfo.preferences.layout_theme === "Light"
                ? "SIDE_NAV_LIGHT"
                : "SIDE_NAV_DARK"
            )
          );
          localStorage.setItem(
            SIDE_NAV_STYLE_CHANGE,
            userInfo.preferences.layout_theme === "Light"
              ? "SIDE_NAV_LIGHT"
              : "SIDE_NAV_DARK"
          );
        }
        // Set prefered Language
        yield put(
          onLocaleChange(
            userInfo.preferences.language === "English" ? "en" : "el"
          )
        );
        localStorage.setItem(
          LANG_OPTION,
          userInfo.preferences.language === "English" ? "en" : "el"
        );
      }
    } catch (err) {
      yield put(showAuthMessage("Invalid email or password"));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      yield dispatch(disconnect());
      window.localStorage.clear();
      yield put(signOutSuccess());
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export default function* rootSaga() {
  yield all([fork(signInWithUsername), fork(signOut)]);
}
