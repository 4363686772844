const IP_ADDRESS_DEV = "0.0.0.0:8000";

let DEMO_CHECK;
if ("REACT_APP_DEMO" in process.env) {
  DEMO_CHECK = process.env.REACT_APP_DEMO;
} else {
  DEMO_CHECK = "false";
}
const IP_ADDRESS_PROD =
  DEMO_CHECK === "true"
    ? "oliveex-back-end-demo.azurewebsites.net"
    : "oliveex-back-end.azurewebsites.net";

const dev = {
  API_ENDPOINT_URL: `http://${IP_ADDRESS_DEV}/api/`,
  ANALYTICS_ENDPOINT_URL: `http://${IP_ADDRESS_DEV}/analytics/`,
  BEVERAGE_ENDPOINT_URL: `http://${IP_ADDRESS_DEV}/beverage/`,
  BREWERIES_ENDPOINT_URL: `http://${IP_ADDRESS_DEV}/breweries/`,
  API_AUTH_URL: `http://${IP_ADDRESS_DEV}/`,
  WS_ENDPOINT_URL: `ws://${IP_ADDRESS_DEV}/`,
};

const prod = {
  API_ENDPOINT_URL: `https://${IP_ADDRESS_PROD}/api/`,
  ANALYTICS_ENDPOINT_URL: `https://${IP_ADDRESS_PROD}/analytics/`,
  BEVERAGE_ENDPOINT_URL: `https://${IP_ADDRESS_PROD}/beverage/`,
  BREWERIES_ENDPOINT_URL: `https://${IP_ADDRESS_PROD}/breweries/`,
  API_AUTH_URL: `https://${IP_ADDRESS_PROD}/`,
  WS_ENDPOINT_URL: `wss://${IP_ADDRESS_PROD}/`,
};

const test = {
  API_ENDPOINT_URL: "https://api.test.com",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
