import {
  ADD_NEW_CALLBACK,
  REMOVE_CALLBACK,
  CONNECT_SUCCESS,
  DISCONNECT_SUCCESS,
} from "../constants/Websocket";

const initState = {
  instance: null,
  callbacks: {},
};

// Payload format -> { callback: f(), command:"name" }
const websocket = (state = initState, action) => {
  switch (action.type) {
    case CONNECT_SUCCESS:
      return {
        ...state,
        instance: action.instance,
      };
    case ADD_NEW_CALLBACK:
      const { command, callback } = action.payload;
      state.callbacks[command] = callback;
      return {
        ...state,
      };
    case REMOVE_CALLBACK:
      const { commandName } = action.payload;
      const newCallbacks = Object.keys(state.callbacks).reduce(
        (object, key) => {
          if (key !== commandName) {
            object[key] = state.callbacks[key];
          }
          return object;
        },
        {}
      );
      state.callbacks = newCallbacks;
      return {
        ...state,
      };
    case DISCONNECT_SUCCESS:
      return {
        ...state,
        callbacks: {},
        instance: null,
      };
    default:
      return { ...state };
  }
};

export default websocket;
