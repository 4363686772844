import { all } from "redux-saga/effects";
import Auth from "./Auth";
import Websocket from "./Websocket";

let isLiveUpdateEnabled;
if ("LIVE_UPDATE_ENABLED" in process.env) {
  isLiveUpdateEnabled =
    String(process.env.LIVE_UPDATE_ENABLED).toLowerCase() === "true";
} else {
  isLiveUpdateEnabled = true;
}

export default function* rootSaga() {
  isLiveUpdateEnabled ? yield all([Auth(), Websocket()]) : yield all([Auth()]);
}
