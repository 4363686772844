import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import User from './User';
import websocket from './Websocket';
import BatchDetails from './BatchDetails';

const reducers = combineReducers({
  theme: Theme,
  user: User,
  auth: Auth,
  websocket: websocket,
  batchDetails: BatchDetails,
});

export default reducers;
