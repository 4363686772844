import history from "../history";
import { AUTH_TOKEN, REFRESH_TOKEN } from "redux/constants/Auth";
import JwtAuthService from "./JwtAuthService";
import { notification } from "antd";
import { IS_STAFF } from "redux/constants/User";

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "Authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
export function requestInterceptor(service) {
  service.interceptors.request.use(
    (config) => {
      const jwtToken = localStorage.getItem(AUTH_TOKEN);
      if (jwtToken) {
        config.headers[TOKEN_PAYLOAD_KEY] = "Token " + jwtToken;
      }
      if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
        history.push(ENTRY_ROUTE);
        window.location.reload();
      }

      return config;
    },
    (error) => {
      // Do something with request error here
      notification.error({
        message: "Error",
      });
      Promise.reject(error);
    }
  );
}

// API respone interceptor
export function responseInterceptor(service) {
  service.interceptors.response.use(
    (response) => {
      return response.data;
    },
    async (error) => {
      let notificationParam = {
        message: "",
      };

      const originalConfig = error.config;

      if (originalConfig.url !== ENTRY_ROUTE && error.response) {
        // Access Token was expired
        if (error.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            const rs = await JwtAuthService.refreshToken();
            const accessToken = rs.access;
            localStorage.setItem(AUTH_TOKEN, accessToken);
            return service(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        } else {
          // Remove token and redirect
          if (
            error.response.status === 400 ||
            error.response.status === 403 ||
            error.response.status === 401
          ) {
            notificationParam.message = "Authentication Fail";
            notificationParam.description = "Please login again";
            localStorage.removeItem(AUTH_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            localStorage.removeItem(IS_STAFF);
            history.push(ENTRY_ROUTE);
            window.location.reload();
          }

          if (error.response.status === 404) {
            notificationParam.message = "Not Found";
          }

          if (error.response.status === 500) {
            notificationParam.message = "Internal Server Error";
          }

          if (error.response.status === 406) {
            notificationParam = {
              message: "Error",
              description: error.response.data.message,
              duration: 0,
            };
          }

          if (error.response.status === 508) {
            notificationParam.message = "Time Out";
          }

          notification.error(notificationParam);
        }
      }

      return Promise.reject(error);
    }
  );
}
