import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import {
  requestInterceptor,
  responseInterceptor,
} from "services/InterceptorFunctionality";

const service = axios.create({
  baseURL: API_BASE_URL,
  timeout: 60000,
});

requestInterceptor(service);
responseInterceptor(service);

export default service;
