import {
  INITIALIZE_ASSET_LIST,
  UPDATE_ASSET_LIST,
  CLEAR_ASSET_LIST,
} from "../constants/User";

const initState = {
  dotStatus: false,
  assets: [],
};

const user = (state = initState, action) => {
  switch (action.type) {
    case INITIALIZE_ASSET_LIST:
      return {
        ...state,
        assets: action.payload,
      };
    case UPDATE_ASSET_LIST:
      return {
        ...state,
        assets: action.payload,
      };
    case CLEAR_ASSET_LIST:
      return {
        ...state,
        assets: [],
      };
    default:
      return { ...state };
  }
};

export default user;
