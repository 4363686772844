import { UPDATE_OPERATIONS_STATUS } from '../constants/BatchDetails';

const initState = {
  addNewOperation: false,
};

const batchDetails = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_OPERATIONS_STATUS:
      return {
        ...state,
        addNewOperation: action.payload,
      };
    default:
      return { ...state };
  }
};

export default batchDetails;
