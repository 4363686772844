import {
  NAV_TYPE_SIDE,
  DIR_LTR,
  SIDE_NAV_DARK,
  SIDE_NAV_LIGHT,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import { env } from "./EnvironmentConfig";
import {
  THEME_OPTION,
  LANG_OPTION,
  SIDE_NAV_STYLE_CHANGE,
  NAV_TYPE_CHANGE,
} from "redux/constants/Theme";

export const APP_NAME = "Oliveex App";
export const API_BASE_URL = env.API_ENDPOINT_URL;
export const API_AUTH_URL = env.API_AUTH_URL;
export const ANALYTICS_BASE_URL = env.ANALYTICS_ENDPOINT_URL;
export const BEVERAGE_BASE_URL = env.BEVERAGE_ENDPOINT_URL;
export const BREWERIES_BASE_URL = env.BREWERIES_ENDPOINT_URL;
export const WS_ENDPOINT_URL = env.WS_ENDPOINT_URL;
export const APP_PREFIX_PATH = "/app";
export const AUTH_PREFIX_PATH = "/auth";

// Get theme, navigation and language from localstorage to pass to THEME_CONFIG
const theme = localStorage.getItem(THEME_OPTION)
  ? localStorage.getItem(THEME_OPTION)
  : "light";

const navigationType =
  localStorage.getItem(NAV_TYPE_CHANGE) === "TOP"
    ? NAV_TYPE_TOP
    : NAV_TYPE_SIDE;

const sideNavTheme =
  localStorage.getItem(SIDE_NAV_STYLE_CHANGE) === "SIDE_NAV_DARK"
    ? SIDE_NAV_DARK
    : SIDE_NAV_LIGHT;

const lang = localStorage.getItem(LANG_OPTION)
  ? localStorage.getItem(LANG_OPTION)
  : "en";

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: sideNavTheme,
  locale: lang,
  navType: navigationType,
  topNavColor: "#2f3a50",
  headerNavColor: "",
  mobileNav: false,
  currentTheme: theme,
  direction: DIR_LTR,
};
