import React, { useEffect } from "react";

export const Footer = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = "https://cdn.headwayapp.co/widget.js";
  document.head.appendChild(script);
  var config = {
    selector: ".headway-badge",
    account: "xabBe7",
  };

  useEffect(() => {
    script.onload = function () {
      window.Headway.init(config);
    };
    return () => {
      script.onload = null;
    };
  }, []);

  return (
    <footer className='footer'>
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <span className='font-weight-semibold'>
          {"Oliveex Private Company"}
        </span>{" "}
        All rights reserved.
      </span>
    </footer>
  );
};
export default Footer;
