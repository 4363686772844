import fetch from "./ApiFetchInterceptor";

const UserService = {};

UserService.getUserInfo = function () {
  return fetch({
    url: `/users/get_user_info/`,
    method: "get",
    headers: {
      "public-request": "true",
      "content-type": "application/json",
    },
  });
};

UserService.getPreferences = function (params) {
  return fetch({
    url: `/users/get_preferences`,
    method: "get",
    headers: {
      "public-request": "true",
      "content-type": "application/json",
    },
    params: params,
  });
};

UserService.updatePreferences = function (data) {
  return fetch({
    url: `/users/update_preferences/`,
    method: "put",
    headers: {
      "public-request": "true",
      "content-type": "application/json",
    },
    data: data,
  });
};

UserService.updateMeasurementUnits = function (data) {
  return fetch({
    url: `/measurement-units/update_measurement_units/`,
    method: "put",
    headers: {
      "public-request": "true",
      "content-type": "application/json",
    },
    data: data,
  });
};

export default UserService;
