import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import { signOut } from "redux/actions/Auth";
import Icon from "components/util-components/Icon";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { Link } from "react-router-dom";
import { COMPANY_NAME, FULL_NAME } from "redux/constants/User";
import { IS_STAFF } from "redux/constants/User";
import { setLocale } from "components/shared-components/AppFunctions";

const menuItem = [
  {
    title: setLocale(true, "settings"),
    icon: SettingOutlined,
    path: `${APP_PREFIX_PATH}/settings`,
  },
];

export const NavProfile = ({ signOut }) => {
  const [fullName, setFullName] = useState(
    localStorage.getItem(FULL_NAME) || ""
  );

  useEffect(() => {
    // Initialize the state with the value from localStorage
    setFullName(localStorage.getItem(FULL_NAME));

    // Listen for changes to the 'myKey' key in localStorage
    const handleFullNameChange = (e) => {
      if (e.key === "FULL_NAME") {
        setFullName(e.newValue);
      }
    };

    // Monitor the 'myKey' key in localStorage for changes
    window.addEventListener("storage", handleFullNameChange);

    return () => {
      // Stop monitor the 'myKey' key in localStorage for changes
      window.removeEventListener("storage", handleFullNameChange);
    };
  }, []);

  const profileImg = (
    <Avatar
      size={30}
      className='font-size-sm'
      style={{ backgroundColor: "#5695d1" }}
    >
      {fullName
        .split(" ")
        .map((n) => n[0])
        .join("")}
    </Avatar>
  );
  const profileMenu = (
    <div className='nav-profile nav-dropdown'>
      <div className='nav-profile-header'>
        <div className='d-flex'>
          <Avatar
            size={30}
            className='font-size-sm'
            style={{ backgroundColor: "#5695d1" }}
          >
            {fullName
              .split(" ")
              .map((n) => n[0])
              .join("")}
          </Avatar>
          <div className='pl-3'>
            <h4 className='mb-0'>{fullName}</h4>
            <span className='text-muted'>
              {" "}
              {localStorage.getItem(COMPANY_NAME)}{" "}
            </span>
          </div>
        </div>
      </div>
      <div className='nav-profile-body'>
        <Menu>
          {localStorage.getItem(IS_STAFF) === "false" &&
            menuItem.map((el, i) => {
              return (
                <Menu.Item key={i}>
                  <Link to={el.path} />
                  <Icon className='mr-3' type={el.icon} />
                  <span className='font-weight-normal'>{el.title}</span>
                </Menu.Item>
              );
            })}
          <Menu.Item key={menuItem.length + 2} onClick={() => signOut()}>
            <span>
              <LogoutOutlined className='mr-3' />
              <span className='font-weight-normal'>
                {setLocale(true, "signOut")}
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement='bottomRight' overlay={profileMenu} trigger={["click"]}>
      <Menu className='d-flex align-item-center' mode='horizontal'>
        <Menu.Item>
          <Avatar src={profileImg} />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navType,
    sideNavTheme,
    navCollapsed,
    topNavColor,
    headerNavColor,
    locale,
    currentTheme,
    direction,
  } = theme;
  return {
    navType,
    sideNavTheme,
    navCollapsed,
    topNavColor,
    headerNavColor,
    locale,
    currentTheme,
    direction,
  };
};

const mapDispatchToProps = {
  signOut,
};
export default connect(mapStateToProps, mapDispatchToProps)(NavProfile);
