import fetch from "auth/FetchInterceptor";
import refreshFetch from "auth/RefreshFetchInterceptor";
import { REFRESH_TOKEN } from "redux/constants/Auth";

const JwtAuthService = {};

JwtAuthService.login = function (data) {
  return fetch({
    url: "/token/",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: data,
  });
};

JwtAuthService.refreshToken = function () {
  return refreshFetch({
    url: "/token/refresh/",
    method: "post",
    headers: {
      "public-request": "true",
    },
    data: {
      refresh: localStorage.getItem(REFRESH_TOKEN),
    },
  });
};

export default JwtAuthService;
