import antdElGR from 'antd/es/locale/el_GR';
import elMsg from '../locales/el_GR.json';

const ElLang = {
  antd: antdElGR,
  locale: 'el-GR',
  messages: {
    ...elMsg,
  },
};
export default ElLang;
