import {
  ADD_NEW_CALLBACK,
  REMOVE_CALLBACK,
  CONNECT,
  CONNECT_SUCCESS,
  DISCONNECT,
  DISCONNECT_SUCCESS,
  NEW_MESSAGE,
} from "../constants/Websocket";

export const newConnect = (data = {}) => {
  return {
    type: CONNECT,
    payload: data,
  };
};

export const connectSuccess = (data = {}) => {
  return {
    type: CONNECT_SUCCESS,
    instance: data,
  };
};

export const newMessage = (data = {}) => {
  return {
    type: NEW_MESSAGE,
    payload: data,
  };
};

export const addNewCallback = (data = {}) => {
  return {
    type: ADD_NEW_CALLBACK,
    payload: data,
  };
};

export const removeCallback = (data = {}) => {
  return {
    type: REMOVE_CALLBACK,
    payload: data,
  };
};

export const disconnect = () => {
  return {
    type: DISCONNECT,
  };
};

export const disconnectSuccess = () => {
  return {
    type: DISCONNECT_SUCCESS,
  };
};
