import enLang from './entries/en_US';
import elLang from './entries/el_GR';
// import frLang from './entries/fr_FR';

const AppLocale = {
  en: enLang,
  el: elLang,
  // fr: frLang,
};

export default AppLocale;
