import {
  DashboardOutlined,
  HomeOutlined,
  ScheduleOutlined,
  UserOutlined,
  BarsOutlined,
  LineChartOutlined,
  BarChartOutlined,
  FileDoneOutlined,
  ExperimentOutlined,
  TableOutlined,
  PieChartOutlined,
  MonitorOutlined,
  FileOutlined,
  BookOutlined,
  FundOutlined,
  PicCenterOutlined,
  NumberOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import {
  STAGES,
  COMPANY_TYPE,
  DEPARTMENT,
  BI_ACCESS,
} from "redux/constants/User";

export default function navigationMenu() {
  const companyType = localStorage.getItem(COMPANY_TYPE);
  const bi_access = JSON.parse(localStorage.getItem(BI_ACCESS));
  const stages = JSON.parse(localStorage.getItem(STAGES));

  function getOverviewMenu() {
    var menu = [
      {
        key: "home",
        path:
          companyType === "Generic"
            ? `${APP_PREFIX_PATH}/assets/Utilities/overview`
            : `${APP_PREFIX_PATH}/home`,
        title: "home",
        icon: HomeOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ];

    if (
      companyType === "Brewery" ||
      companyType === "Beverage" ||
      companyType === "Winery"
    ) {
      menu.push(
        {
          key: "assetOverview",
          title: "assets",
          icon: TableOutlined,
          breadcrumb: false,
          submenu:
            stages &&
            stages.map((stage) => {
              return {
                key: stage,
                path: `${APP_PREFIX_PATH}/assets/${stage}/overview`,
                title: `stage.${stage}`,
                breadcrumb: false,
                submenu: [],
              };
            }),
        },
        {
          key: "insights",
          path: `${APP_PREFIX_PATH}/insights`,
          title: "insights",
          icon: MonitorOutlined,
          breadcrumb: false,
          submenu: [],
        },
        {
          key: "events",
          path: `${APP_PREFIX_PATH}/events`,
          title: "events",
          icon: BarsOutlined,
          breadcrumb: false,
          submenu: [],
        }
      );
    }
    if (stages && stages.includes("Packaging")) {
      menu.push({
        key: "lineMonitoring",
        path: `${APP_PREFIX_PATH}/line-monitoring`,
        title: "Line Monitoring",
        icon: FundOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    return menu;
  }

  function getManagementMenu() {
    var menu = [];

    if (companyType === "Brewery" || companyType === "Beverage") {
      menu.push({
        key: "recipes",
        path: `${APP_PREFIX_PATH}/recipes`,
        title: "recipes",
        icon: ExperimentOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    if (stages && stages.includes("Packaging")) {
      menu.push({
        key: "sortNumbers",
        path: `${APP_PREFIX_PATH}/sort-numbers`,
        title: "sortNumbers",
        icon: NumberOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    if (companyType !== "Generic") {
      menu.push({
        key: "batches",
        path: `${APP_PREFIX_PATH}/batches/`,
        title: "batches",
        icon: ScheduleOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    if (companyType === "Brewery") {
      menu.push({
        key: "cip",
        path: `${APP_PREFIX_PATH}/cip/`,
        title: "CIP",
        icon: ScheduleOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    if (companyType === "Brewery") {
      menu.push({
        key: "dataEntry",
        path: `${APP_PREFIX_PATH}/data-entry`,
        title: "data.entry",
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    if (companyType === "Brewery" || companyType === "Beverage") {
      menu.push({
        key: "inventory",
        path: `${APP_PREFIX_PATH}/inventory`,
        title: "inventory",
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    menu.push({
      key: "thresholds",
      path: `${APP_PREFIX_PATH}/thresholds`,
      title: "thresholds",
      icon: PicCenterOutlined,
      breadcrumb: false,
      submenu: [],
    });

    menu.push({
      key: "users",
      path: `${APP_PREFIX_PATH}/user-list`,
      title: "users",
      icon: UserOutlined,
      breadcrumb: false,
      submenu: [],
    });

    return menu;
  }

  function getAnalyticsMenu() {
    var menu = [];

    if (
      (companyType === "Brewery" || companyType === "Beverage") &&
      bi_access &&
      localStorage.getItem(DEPARTMENT) !== "Shift Monitoring"
    ) {
      menu.push({
        key: "productivity",
        path: `${APP_PREFIX_PATH}/productivity`,
        title: "productivity",
        icon: PieChartOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    if (companyType === "Brewery" && bi_access) {
      menu.push({
        key: "recipeAnalytics",
        path: `${APP_PREFIX_PATH}/recipe-analytics`,
        title: "recipe.analytics",
        icon: ExperimentOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    if (companyType === "Brewery" && bi_access) {
      menu.push({
        key: "batchComparison",
        path: `${APP_PREFIX_PATH}/batch-comparison`,
        title: "comparison.batch",
        icon: BarChartOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    menu.push({
      key: "dataComparison",
      path: `${APP_PREFIX_PATH}/data-comparison`,
      title: "comparison.data",
      icon: BarChartOutlined,
      breadcrumb: false,
      submenu: [],
    });

    if (companyType === "Generic") {
      menu.push({
        key: "reports",
        path: `${APP_PREFIX_PATH}/reports`,
        title: "reports",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      });
    }

    return menu;
  }

  return [
    {
      key: "dashboard",
      title: "OVERVIEW",
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: getOverviewMenu(),
    },
    {
      key: "management",
      title: "MANAGEMENT",
      icon: BarsOutlined,
      breadcrumb: false,
      submenu: getManagementMenu(),
    },
    {
      key: "analytics",
      title: "ANALYTICS",
      icon: LineChartOutlined,
      breadcrumb: false,
      submenu: getAnalyticsMenu(),
    },
  ];
}
