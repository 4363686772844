import React, { useEffect, useRef } from "react";
import moment from "moment";
import IntlMessage from "components/util-components/IntlMessage";
import {
  BatchDetailsGeneralInfoPalette,
  OperationsPalette,
} from "constants/ColorConstant";
import translationKeys from "lang/locales/en_US.json";
import { COMPANY_TYPE, MEASUREMENT_UNITS } from "redux/constants/User";
import { LANG_OPTION } from "redux/constants/Theme";

export const isStage = (companyType, stageType, actualStage) => {
  return (
    localStorage.getItem(COMPANY_TYPE) === companyType &&
    stageType === actualStage
  );
};

// Function to translate a given string
export function setLocale(isLocaleOn, localeKey, intl) {
  let translatedString = localeKey.toString();

  // If localKey is not found in the translationKeys, return the key itself
  if (!translationKeys[localeKey] && localeKey.includes("layer.")) {
    return localeKey.replace("layer.", "");
  }

  if (isLocaleOn) {
    if (intl) {
      translatedString = intl.formatMessage({ id: localeKey });
    } else {
      translatedString = <IntlMessage id={localeKey} description={String} />;
    }
  }
  return isLocaleOn ? translatedString : localeKey.toString();
}

// Custom formatter for values
export const numberFormatter = Intl.NumberFormat("de-DE");

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  });

  return isMounted;
}

// Function to convert first letter of a string to uppercase
export function capitalizeFirstLetter(str) {
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
}

// Function to convert first letter of each word in a string to uppercase
export function capitalizeFirstLetterOfEachWord(str) {
  return str
    .split(" ") // Split the string by spaces to get an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Join the words back into a single string with spaces
}

export function replaceCapitalWithLowercase(inputString) {
  // Use a regular expression to match capital letters (A-Z) globally
  return inputString.replace(/[A-Z]/g, function (match) {
    // Convert the matched capital letter to lowercase
    return match.toLowerCase();
  });
}

// Function to convert seconds to HH:MM:SS format
export function secondsToHMS(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

// Function to truncate a long string and show ... instead.
export function truncateLongString(str, n) {
  return str.length > n ? str.substring(0, n - 3) + ".." : str;
}

// Function to get translation for a given capability type
export function getCapability(capability, intl) {
  let str = "capability." + capability;

  return setLocale(true, str, intl);
}

// Function to get proper capability metric
export function getCapabilityMetric(capabilityType, addSpaceBeforeUnit = true) {
  const measurementUnits = JSON.parse(localStorage.getItem(MEASUREMENT_UNITS));

  if (capabilityType === "Set Point") {
    capabilityType = "Temperature";
  }

  let measurementUnit = measurementUnits.find(
    (item) => item.capability_type === capabilityType
  );

  if (!measurementUnit) {
    return "";
  }

  let unit = measurementUnit["value"];

  return (addSpaceBeforeUnit ? " " : "") + unit;
}

// Get battery Tag color according to level
export function getBatteryColor(level) {
  return level <= 10
    ? "red"
    : level > 10 && level <= 35
    ? "orange"
    : level > 35 && level <= 50
    ? "yellow"
    : level > 50 && level <= 90
    ? "blue"
    : "green";
}

// Get battery image according to level
export function getBatteryImage(level) {
  return level === -1
    ? "plug.png"
    : level > -1 && level <= 10
    ? "battery_empty.png"
    : level > 10 && level <= 35
    ? "battery_low.png"
    : level > 35 && level <= 50
    ? "battery_half.png"
    : level > 50 && level <= 90
    ? "battery_high.png"
    : "battery_full.png";
}

// Function to get translation for a given process
export function getProcess(process, intl) {
  let str = "operation." + process;
  return setLocale(true, str, intl);
}

// Function to get translation for a given process
export function getStage(stage) {
  let str = "stage." + stage;
  return setLocale(true, str);
}

// Function to get translation for a given asset type
export function getType(type, intl) {
  let str = "asset.type." + type;
  return setLocale(true, str, intl);
}

// Get color according to the progress of the batch
export function getProgressStatusColor(progress) {
  if (progress >= 80) {
    return BatchDetailsGeneralInfoPalette[5];
  } else if (progress < 80 && progress >= 60) {
    return BatchDetailsGeneralInfoPalette[4];
  } else if (progress < 60 && progress >= 30) {
    return BatchDetailsGeneralInfoPalette[3];
  } else {
    return BatchDetailsGeneralInfoPalette[2];
  }
}

// Get color according to the process
export function getOperationColor(process) {
  let companyType = localStorage.getItem(COMPANY_TYPE);
  if (companyType === "Brewery") {
    return process === "Milling"
      ? OperationsPalette[0]
      : process === "Mashing"
      ? OperationsPalette[1]
      : process === "Lautering"
      ? OperationsPalette[2]
      : process === "Boiling"
      ? OperationsPalette[3]
      : ["Fermentation", "Whirlpool"].includes(process)
      ? OperationsPalette[4]
      : ["Conditioning", "Wort-Cooling"].includes(process)
      ? OperationsPalette[5]
      : process === "CIP Base"
      ? OperationsPalette[0]
      : process === "CIP Acid"
      ? OperationsPalette[1]
      : process === "CIP Disinfectant"
      ? OperationsPalette[2]
      : OperationsPalette[6];
  } else if (companyType === "Beverage") {
    return [
      "Bag Sugar Transfer",
      "Silo Sugar Transfer",
      "Mixing",
      "PET Preparation",
    ].includes(process)
      ? OperationsPalette[0]
      : [
          "Sugar Dissolution Preparation",
          "Sugar Syrup Transfer",
          "Filling",
        ].includes(process)
      ? OperationsPalette[1]
      : ["Sugar Dissolution", "Juice Load", "Labelling"].includes(process)
      ? OperationsPalette[2]
      : ["Pasteurizing", "Juice Transfer", "Packing"].includes(process)
      ? OperationsPalette[3]
      : process === "Storage"
      ? OperationsPalette[4]
      : ["Syrup Transfer", "Palletizing"].includes(process)
      ? OperationsPalette[5]
      : OperationsPalette[6];
  }

  return OperationsPalette[6];
}

// Function to generate the progress for an operation
export const generateProgress = (startingDate, endDate) => {
  if ((endDate === startingDate) | (endDate < moment())) {
    return 100;
  }
  let progressResult =
    moment().diff(startingDate, "seconds") /
    endDate.diff(startingDate, "seconds");

  let formatProgress =
    Math.floor(progressResult * Math.pow(10, 2)) / Math.pow(10, 2);
  let progressPercentage = Math.round(formatProgress * 100);

  return progressPercentage;
};

// Function to generate the duration for an operation
export const generateDuration = (
  startingDate,
  endDate,
  activeBatches = false
) => {
  const divmod = (x, y) => [Math.floor(x / y), x % y];

  if ((endDate === startingDate) | (endDate < moment()) | activeBatches) {
    let durationInSeconds = endDate.diff(startingDate, "seconds");
    let days = divmod(durationInSeconds, 86400);
    let hours = divmod(days[1], 3600);
    let minutes = divmod(hours[1], 60);
    return [days[0], hours[0], minutes[0]];
  } else {
    return "-";
  }
};

// Function to get the day, hour, or minute of a process (the largest available time unit is preferred)
export const getDaysHoursMinutes = (
  startingDate,
  endDate,
  intl,
  activeBatches
) => {
  let duration = generateDuration(startingDate, endDate, activeBatches);

  let finalObject = Array.isArray(duration)
    ? duration[0] !== 0 && (duration[1] !== 0 || duration[2] !== 0)
      ? setLocale(true, "Day", intl) + " " + (duration[0] + 1)
      : duration[0] !== 0
      ? setLocale(true, "Day", intl) + " " + duration[0]
      : duration[1] !== 0 && duration[2] !== 0
      ? setLocale(true, "Hour", intl) + " " + (duration[1] + 1)
      : duration[1] !== 0
      ? setLocale(true, "Hour", intl) + " " + duration[1]
      : duration[2] !== 0
      ? setLocale(true, "Minute", intl) + " " + duration[2]
      : "-"
    : "-";

  return finalObject;
};

// Function to get proper image based on company type and process
// export function getAssetImage(type, process, batch, theme, assetType) {
//   console.log(assetType);
//   // Generic
//   if (type === "Generic") {
//     return `${
//       theme === "light" ? "light_theme/" : "dark_theme/"
//     }generic_asset.png`;
//   }
//   // Brewery
//   else if (type === "Brewery") {
//     if (
//       (process === "03-Mashing") |
//       (process === "04-Lautering") |
//       (process === "05-Boiling")
//     ) {
//       if (batch !== null) {
//         return "brewery/kettle.gif";
//       } else {
//         return "kettle_empty.gif";
//       }
//     } else if (process === "07-Wort-Cooling") {
//       if (batch !== null) {
//         return "brewery/wort_cooling.gif";
//       } else {
//         return "brewery/wort_cooling_empty.gif";
//       }
//     } else if (process === "08-Fermentation") {
//       return "brewery/unitank.gif";
//     } else if (process === "09-Conditioning") {
//       return "brewery/unitank_cooling.gif";
//     } else if (process === "11-Storage") {
//       if (assetType === "Unitank") {
//         return "brewery/unitank_cooling.gif";
//       }
//       if (batch !== null) {
//         return "brewery/bbt.gif";
//       } else {
//         return "brewery/bbt.gif";
//       }
//     } else if (process === "12-Utilities") {
//       return "brewery/utilities.png";
//     } else {
//       return "unitank_empty.gif";
//     }
//   }
//   // Winery
//   else if (type === "Winery") {
//     if (process === "08-Fermentation") {
//       if (batch !== null) {
//         return "winery/red/unitank.gif";
//       } else {
//         return "unitank_empty.gif";
//       }
//     } else {
//       if (batch !== null) {
//         return "winery/red/storage.gif";
//       } else {
//         return "bbt_empty.gif";
//       }
//     }
//   }
//   // else if (type === "Dairy") {
//   //   if (process === "08-Fermentation") {
//   //     if (batch !== null) {
//   //       return "fermentation_dairy.png";
//   //     } else {
//   //       return "empty.gif";
//   //     }
//   //   } else {
//   //     if (batch !== null) {
//   //       return "storage_dairy.png";
//   //     } else {
//   //       return "bbt_empty.png";
//   //     }
//   //   }
//   // }
// }

export function getAssetImage(companyType, process, batch, theme, assetType) {
  if (companyType === "Generic") {
    return `${
      theme === "light" ? "light_theme/" : "dark_theme/"
    }${replaceCapitalWithLowercase(companyType)}.png`;
  }
  let assetImageBasePath = `${companyType}/${assetType}${
    process.substring(3) === "Utilities" || process.substring(3) === "Taproom"
      ? ""
      : "_" + process.substring(3)
  }.gif`;

  return replaceCapitalWithLowercase(assetImageBasePath);
}

// Get Tag color according to priority
export function getInsightPriorityColor(priority) {
  return priority === "Urgent"
    ? "red"
    : priority === "High"
    ? "orange"
    : priority === "Medium"
    ? "yellow"
    : "green";
}

// Get Tag color according to status
export function getInsightStatusColor(status) {
  return status === "Open"
    ? "red"
    : status === "Acknowledged"
    ? "purple"
    : "green";
}

// Method to get a more descriptive duration in days, hours, minutes
export function getDescriptiveDuration(duration) {
  // Divmod method
  const divmod = (x, y) => [Math.floor(x / y), x % y];

  // Get days hours and minutes
  let days = divmod(duration, 86400);
  let hours = divmod(days[1], 3600);
  let minutes = divmod(hours[1], 60);

  return days[0] + " days, " + hours[0] + " hours, " + minutes[0] + " minutes";
}

// Method to get a more descriptive duration in days, hours, minutes given minutes
export function getDescriptiveDurationGivenMinutes(duration, intl, type) {
  const divmod = (x, y) => [Math.floor(x / y), x % y];

  // Get days hours and minutes
  const days = divmod(duration, 60 * 24);
  const hours = divmod(days[1], 60);
  const minutes = hours[1];

  const daysString =
    days[0] > 0
      ? days[0] + " " + setLocale(true, days[0] === 1 ? "day" : "days", intl)
      : "";

  const hoursString =
    hours[0] > 0
      ? (type === "hm" ? "" : ", ") +
        hours[0] +
        " " +
        setLocale(true, hours[0] === 1 ? "hour" : "hours", intl)
      : "";

  const minutesString =
    minutes > 0
      ? (hours[0] === 0 && type === "hm" ? "" : ", ") +
        minutes +
        " " +
        setLocale(true, minutes === 1 ? "minute" : "minutes", intl)
      : "";

  return type === "hm" ? hoursString + minutesString : daysString + hoursString;
}

// Function to get the description of the datetime object based on the selected option (month, quarter, semester, year)
export function getDatetimeDescription(start, type) {
  const languageOption = localStorage.getItem(LANG_OPTION);
  const date = new Date(start);

  // 1. Get the month and year
  if (type === "month") {
    return new Intl.DateTimeFormat(languageOption, {
      month: "long",
      year: "numeric",
    }).format(date);
  }
  // 2. Get the quarter and year
  else if (type === "quarter") {
    return (
      "Q" + Math.ceil((date.getMonth() + 1) / 3) + " " + date.getFullYear()
    );
  }
  // 3. Get the semester and year
  else if (type === "semester") {
    return (
      "S" + Math.ceil((date.getMonth() + 1) / 6) + " " + date.getFullYear()
    );
  }
  // 4. Get the year
  else {
    return new Intl.DateTimeFormat(languageOption, {
      year: "numeric",
    }).format(date);
  }
}

export function getStateDescription(state) {
  switch (state) {
    case 1:
      return "Stopped";
    case 2:
      return "Prepared";
    case 4:
      return "Prepared";
    case 8:
      return "Lack";
    case 16:
      return "Tailback";
    case 32:
      return "Lack Branch Line";
    case 64:
      return "Tailback Branch Line";
    case 128:
      return "Operating";
    case 256:
      return "Stopping";
    case 512:
      return "Aborting";
    case 1024:
      return "Equipment Failure";
    case 2048:
      return "External Failure";
    case 4096:
      return "Emergency Stop";
    case 8192:
      return "Holding";
    case 16384:
      return "Held";
    case 32768:
      return "Idle";
    case 65536:
      return "Unholding";
    case 131072:
      return "Suspending";
    case 262144:
      return "Unsuspending";
    case 524288:
      return "Resetting";
    case 1048576:
      return "Clearing";
    default:
      return "Offline";
  }
}

export function getStateColor(state) {
  if (
    state === "Starting" ||
    state === "Stopping" ||
    state === "Aborting" ||
    state === "Holding" ||
    state === "Unholding" ||
    state === "Unsuspending" ||
    state === "Suspending" ||
    state === "Resetting"
  ) {
    return "#FFF865"; // Yellow
  } else if (
    state === "Prepared" ||
    state === "Lack" ||
    state === "Held" ||
    state === "Idle" ||
    state === "Tailback" ||
    state === "Lack Branch Line" ||
    state === "Tailback Branch Line"
  ) {
    return "#EF955C"; // Orange
  } else if (state === "Operating") {
    return "#8BCF7F"; // Green
  } else if (state === "Clearing") {
    return "#84D1B6"; // Blue
  } else {
    return "#DE676D"; // Red
  }
}
export function getCipStateColor(state) {
  if (
    state === "WAITING CIP TO START" ||
    state === "ALARM CAUSTIC - TEMP" ||
    state === "CHECK CAUSTIC" ||
    state === "STANDBY GAI" ||
    state === "FLUSH OBJECT" ||
    state === "EMPTY OBJECT" ||
    state === "END SEQUENCE"
  ) {
    return "#FFF865"; // Yellow
  } else if (
    state === "EMPTY OBJECT1" ||
    state === "CAUSTIC CLEANING" ||
    state === "EMPTY OBJECT FROM CAUSTIC" ||
    state === "CAUSTIC RECOVERY" ||
    state === "FLUSH LINE FROM CAUSTIC"
  ) {
    return "#EF955C"; // Orange
  } else if (
    state === "EMPTY OBJECT2" ||
    state === "ACID CLEANING" ||
    state === "EMPTY OBJECT FROM ACID" ||
    state === "ACID RECOVERY" ||
    state === "FLUSH LINE FROM ACID"
  ) {
    return "#8BCF7F"; // Green
  } else if (
    state === "EMPTY OBJECT3" ||
    state === "DISINFECTION" ||
    state === "FLUSH CIP" ||
    state === "EMPTY OBJECT FROM DESI" ||
    state === "DRAIN LINE" ||
    state === "DESI RECOVERY" ||
    state === "FLUSH LINE FROM DESI" ||
    state === "EMPTY OBJECT3"
  ) {
    return "#84D1B6"; // Blue
  } else {
    return "#DE676D"; // Red
  }
}
export function getProgramColor(program) {
  if (program === "Start up" || program === "Run Down") {
    return "#FFF865"; // Yellow
  } else if (program === "Changeover" || program === "Break") {
    return "#FFCA5A"; // Peach
  } else if (program === "Production") {
    return "#8BCF7F"; // Green
  } else if (program === "Clean") {
    return "#84D1B6"; // Blue
  } else {
    return "#DE676D"; // Red
  }
}

export function getSortNumberColor(type) {
  if (type === "Changeover") {
    return "#EF955C"; // Orange
  } else {
    return "#8BCF7F"; // Green
  }
}

export function getProgramDescription(program) {
  switch (program) {
    case 0:
      return "Undefined";
    case 1:
      return "Production";
    case 2:
      return "Start up";
    case 4:
      return "Run Down";
    case 8:
      return "Clean";
    case 16:
      return "Changeover";
    case 32:
      return "Maintenance";
    case 64:
      return "Break";
    default:
      return "Undefined";
  }
}

// Create start & end date format after selecting semester
function createDateFromSemester(year, semesterNumber) {
  let start = "";
  let end = "";

  // January through June
  if (semesterNumber === 1) {
    start = year + "-01-01";
    end = year + "-06-30";
  }
  // July through December
  else {
    start = year + "-07-01";
    end = year + "-12-31";
  }

  return [start, end];
}

// Create start & end date format after selecting quarter
function createDateFromQuarter(year, quarterNumber) {
  let start = "";
  let end = "";

  // January through March
  if (quarterNumber === 1) {
    start = year + "-01-01";
    end = year + "-03-31";
  }
  // April through June
  else if (quarterNumber === 2) {
    start = year + "-04-01";
    end = year + "-06-30";
  }
  // July through September
  else if (quarterNumber === 3) {
    start = year + "-07-01";
    end = year + "-09-30";
  }
  // October through December
  else {
    start = year + "-10-01";
    end = year + "-12-31";
  }

  return [start, end];
}

export function createDatePickerObject(selectedDate) {
  // Initialize date object
  let dateObject = {
    type: selectedDate["type"],
  };

  // Different functionality depending on date type

  // Year
  if (selectedDate["type"] === "year") {
    dateObject["start"] = moment(selectedDate["year"], "YYYY")
      .startOf("year")
      .format("YYYY-MM-DD");
    dateObject["end"] = moment(selectedDate["year"], "YYYY")
      .endOf("year")
      .format("YYYY-MM-DD");
  }

  // Semester
  else if (selectedDate["type"] === "semester") {
    const dates = createDateFromSemester(
      selectedDate["year"],
      selectedDate["semester"]
    );
    dateObject["start"] = dates[0];
    dateObject["end"] = dates[1];
  }

  // Quarter
  else if (selectedDate["type"] === "quarter") {
    const dates = createDateFromQuarter(
      selectedDate["year"],
      selectedDate["quarter"]
    );
    dateObject["start"] = dates[0];
    dateObject["end"] = dates[1];
  }

  // Month
  else {
    dateObject["start"] = moment(
      selectedDate["year"] + "-" + selectedDate["month"],
      "YYYY-M"
    )
      .startOf("month")
      .format("YYYY-MM-DD");
    dateObject["end"] = moment(
      selectedDate["year"] + "-" + selectedDate["month"],
      "YYYY-M"
    )
      .endOf("month")
      .format("YYYY-MM-DD");
  }

  return dateObject;
}
