import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import { COMPANY_TYPE, IS_STAFF } from "redux/constants/User";
import NotFound from "./notFound-views";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const Views = (props) => {
  const { locale, token, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path='/'>
            {localStorage.getItem(IS_STAFF) === null ? (
              <Redirect
                to={{
                  pathname: AUTH_PREFIX_PATH,
                  state: { from: location },
                }}
              />
            ) : localStorage.getItem(COMPANY_TYPE) === "Generic" ? (
              <Redirect to={`${APP_PREFIX_PATH}/assets/Utilities/overview`} />
            ) : (
              <Redirect to={`${APP_PREFIX_PATH}/home`} />
            )}
          </Route>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          {localStorage.getItem(IS_STAFF) === null ? (
            <Redirect
              to={{
                pathname: AUTH_PREFIX_PATH,
                state: { from: location },
              }}
            />
          ) : (
            <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
              <AppLayout direction={direction} location={location} />
            </RouteInterceptor>
          )}
          <Route component={NotFound} />
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token, isStaff } = auth;
  return { locale, direction, token, isStaff };
};

export default withRouter(connect(mapStateToProps)(Views));
