export const IS_STAFF = "IS_STAFF";
export const UPDATE_IS_STAFF = "UPDATE_IS_STAFF";
export const COMPANY = "COMPANY";
export const COMPANY_NAME = "COMPANY_NAME";
export const COMPANY_TYPE = "COMPANY_TYPE";
export const USERNAME = "USERNAME";
export const EMAIL = "EMAIL";
export const FULL_NAME = "FULL_NAME";
export const DEPARTMENT = "DEPARTMENT";
export const BI_ACCESS = "BI_ACCESS";

export const INITIALIZE_ASSET_LIST = "INITIALIZE_ASSET_LIST";
export const UPDATE_ASSET_LIST = "UPDATE_ASSET_LIST";
export const CLEAR_ASSET_LIST = "CLEAR_ASSET_LIST";

export const FIRST_PRODUCTION_DATE = "FIRST_PRODUCTION_DATE";
export const STAGES = "STAGES";

export const MEASUREMENT_UNITS = "MEASUREMENT_UNITS";
