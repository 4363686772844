// blue, orange, dark, green, lighter green, lightest green
export const BatchDetailsGeneralInfoPalette = [
  "#0099DD",
  "#F28705",
  "#0B2B40",
  "#1E5959",
  "#3B8C6E",
  "#89D99D",
];

// orange, green, red
// chart, increase, decrease
export const KineticsPalette = ["#F28705", "#59D986", "#D92211"];

// green, orange
export const CoolingPerformancePalette = [
  "#026E81",
  "#00ABBD",
  "#0099DD",
  "#FF9933",
  "#662400",
  "#B33F00",
];

// orange, green, dark, dark green
export const CoolingCyclesPalette = [
  "#026E81",
  "#00ABBD",
  "#0099DD",
  "#FF9933",
  "#662400",
  "#B33F00",
];

// orange, light blue, green, blue
export const CoolingTimePalette = [
  "#026E81",
  "#00ABBD",
  "#0099DD",
  "#FF9933",
  "#662400",
  "#B33F00",
];

// dark, green, lighter green, lightest green, orange, blue
// Mashing, Lautering, Boiling, Wort-Cooling, Fermentation, Conditioning, Storage
export const OperationsPalette = [
  "#012E40",
  "#025159",
  "#038C8C",
  "#03A696",
  "#F28705",
  "#0099DD",
  "#A1C7E0",
];

// cyan, blue
export const BatchDetailsChartsPalette = ["#00E3CC", "#0099DD"];

export const AssetDetailsChartPalette1Light = [
  "#00ABBD",
  "#A1C7E0",
  "#F47405",
  "#FF9933",
  "#006663",
  "#97A6A0",
  "#662400",
  "#B33F00",
  "#FACFCE",
  "#D92525",
  "#7A577A",
];

export const AssetDetailsChartPalette1Dark = [
  "#00ABBD",
  "#A1C7E0",
  "#FF9933",
  "#FFEC5C",
  "#7A577A",
  "#FACFCE",
  "#662400",
  "#B33F00",
  "#006663",
  "#D92525",
  "#97A6A0",
];

// green, light blue
export const AssetDetailsChartPalette2 = ["#00ABBD", "#A1C7E0"];
