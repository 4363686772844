import axios from "axios";
import { API_AUTH_URL } from "configs/AppConfig";
import history from "../history";
import { AUTH_TOKEN } from "redux/constants/Auth";
import { notification } from "antd";

const service = axios.create({
  baseURL: API_AUTH_URL,
  timeout: 60000,
});

// Config
const ENTRY_ROUTE = "/auth/login";
const TOKEN_PAYLOAD_KEY = "authorization";
const PUBLIC_REQUEST_KEY = "public-request";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      history.push(ENTRY_ROUTE);
      window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: "Error",
    });
    Promise.reject(error);
  }
);

// API response interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "",
    };

    // Remove token and redirect
    if (
      error.response.status === 400 ||
      error.response.status === 403 ||
      error.response.status === 401
    ) {
      window.localStorage.clear();
    }

    if (error.response.status === 404) {
      notificationParam.message = "Not Found";
      notification.error(notificationParam);
    }

    if (error.response.status === 500) {
      notificationParam.message = "Internal Server Error";
      notification.error(notificationParam);
    }

    if (error.response.status === 508) {
      notificationParam.message = "Time Out";
      notification.error(notificationParam);
    }

    return Promise.reject(error);
  }
);

export default service;
